import {Button, Checkbox, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup} from "@mui/material";
import {t} from "i18next";
import CustomTextField from "../customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import {LeadInterface} from "../../interfaces/leadInterface";
import CustomDialog from "../Dialog/customDialog";
import * as React from "react";
import {changeLeadHookData, changePropertyHookData} from "../../hooks/leadsHook";
import {validationEmail, validationPhone, validationTextNotEmpty} from "../../Shared/utils/validations";
import LegalTextContent from "../../Shared/sharedTexts/legalTextContent";

interface props {
    lead: LeadInterface,
    setLead: Function,
    customFunction: Function,
}

const UserForm = ({lead, setLead, customFunction}: props) => {


    const [notValidated, setNotValidated] = useState(true);

    const [showMessage, setShowMessage] = useState({
        name: {
            error: false,
            message: 'Este campo es obligatorio'
        },
        email: {
            error: false,
            message: 'Debe de ser correo electrónico válido'
        },
        phone: {
            error: false,
            message: 'Debe de ser un teléfono válido'
        }
    });

    const [openDialog, setOpenDialog] = React.useState(false);

    const [usePolitical, setUsePolitical] = useState(false);

    const changeLeadData = (key: string, value: any) => {

        if (key === 'name') validationNameForm(value);
        if (key === 'email') validationEmailForm(value);
        if (key === 'phone') validationPhoneForm(value);


        changeLeadHookData(lead, setLead, key, value);
    }

    const changePropertyData = (key: string, value: any) => {
        changePropertyHookData(lead, setLead, key, value);
    }

    const openPoliticalPrivateDialog = () => {
        if (usePolitical) {
            setUsePolitical(!usePolitical);
        } else {
            setOpenDialog(true);
        }
    }

    const checkedUsePoliticalAcceptance = () => {
        setUsePolitical(!usePolitical);
    }

    const validationNameForm = (value: string) => {
        const isValid = validationTextNotEmpty(value);
        setShowMessage(prevState => ({...prevState, name: {...prevState.name, error: isValid}}));
    }

    const validationEmailForm = (value: string) => {
        const isValid = validationEmail(value);
        setShowMessage(prevState => ({...prevState, email: {...prevState.email, error: isValid}}));
    }

    const validationPhoneForm = (value: string) => {
        const isValid = validationPhone(value);
        setShowMessage(prevState => ({...prevState, phone: {...prevState.phone, error: isValid}}));
    }

    const validateUserDataForm = () => {
        if (lead.user.name !== '' && lead.user.email !== '' && lead.user.phone !== '' && usePolitical &&
            !showMessage.name.error && !showMessage.email.error && !showMessage.phone.error) {
            setNotValidated(false);
        } else {
            setNotValidated(true);
        }
    }

    useEffect(() => {
        validateUserDataForm();
    }, [lead.user, usePolitical]);

    return (
        <>
            <Grid container spacing={5}>
                <Grid item xs={12} sm={11} md={6}>
                    <Grid container spacing={3}>
                        <Grid item xs={12}>
                            <CustomTextField fullWidth
                                             size={'small'}
                                             required={true}
                                             value={lead.user.name}
                                             label={t('mainPage.leadForm.propertyUserDetails.propertyUserDetailsFullName')}
                                             id="userFormNameDetail"
                                             error={showMessage.name.error}
                                             helperText={showMessage.name.error ? showMessage.name.message : ''}
                                             onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('name', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomTextField fullWidth
                                             size={'small'}
                                             required={true}
                                             type={'email'}
                                             value={lead.user.email}
                                             label={t('mainPage.leadForm.propertyUserDetails.propertyUserDetailsEmail')}
                                             id="userFormEmailDetail"
                                             error={showMessage.email.error}
                                             helperText={showMessage.email.error ? showMessage.email.message : ''}
                                             onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('email', e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <CustomTextField fullWidth
                                             size={'small'}
                                             required={true}
                                             value={lead.user.phone}
                                             label={t('mainPage.leadForm.propertyUserDetails.propertyUserDetailsPhone')}
                                             id="userFormPhoneDetail"
                                             error={showMessage.phone.error}
                                             helperText={showMessage.phone.error ? showMessage.phone.message : ''}
                                             onChange={(e: ChangeEvent<HTMLInputElement>) => changeLeadData('phone', e.target.value)}
                            />
                        </Grid>
                    </Grid>

                </Grid>
                <Grid item xs={12} sm={11} md={6}>
                    <Grid item xs={12} md={12}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">¿Quieres que un Agente Winahost te llame para darte
                                información más detallada de nuestros servicios para ayudarte a rentabilizar tu
                                vivienda?</FormLabel>
                            <RadioGroup aria-label="call" name="call" value={lead.property.call}
                                        onChange={(e: ChangeEvent<HTMLInputElement>) => changePropertyData('call', e.target.value)}>
                                <FormControlLabel value="yes" control={<Radio/>} label="Sí"/>
                                <FormControlLabel value="no" control={<Radio/>} label="No"/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={11} md={6}>
                    <Grid item xs={12} md={12} mb={5}>
                        <FormControlLabel
                            control={<Checkbox checked={usePolitical} onClick={openPoliticalPrivateDialog}/>}
                            label="Aceptar políticas de uso y privacidad"/>
                    </Grid>
                    <Button
                        disabled={notValidated}
                        variant={'outlined'}
                        className={'custom-winahost-button'}
                        style={{width: '100%'}}
                        onClick={() => customFunction()}
                    >
                        {'Desbloquea tus ingresos e indicadores potenciales'}
                    </Button>
                </Grid>
                <CustomDialog title={'Política de Privacidad'} content={<LegalTextContent/>} open={openDialog}
                              setOpen={setOpenDialog} customFunction={checkedUsePoliticalAcceptance}/>
            </Grid>
        </>
    );
}

export default UserForm;
