import {
    Checkbox,
    FormControlLabel,
    Grid,
    Typography
} from "@mui/material";
import {createMarkup} from "../../../../Shared/utils/textUtilsResources";
import * as React from "react";
import {LeadInterface} from "../../../../interfaces/leadInterface";
import CustomTextField from "../../../../components/customComponents/customTextField";
import {ChangeEvent, useEffect, useState} from "react";
import {changeLeadHookData} from "../../../../hooks/leadsHook";
import CustomDialog from "../../../../components/Dialog/customDialog";
import {validationFormPage} from "../../landingForm";
import LegalTextContent from "../../../../Shared/sharedTexts/legalTextContent";

interface props {
    lead: LeadInterface,
    setLead: Function,
    validationFormPage: validationFormPage,
    setValidationFormPage: Function
}

const LeadContactLandingForm = ({lead, setLead, validationFormPage, setValidationFormPage}: props) => {

    const [openDialog, setOpenDialog] = useState(false);
    const [usePolitical, setUsePolitical] = useState(false);


    const openPoliticalPrivateDialog = () => {
        if (usePolitical) {
            setUsePolitical(!usePolitical);
        }
        else {
            setOpenDialog(true);
        }
    }

    const checkedUsePoliticalAcceptance = () => {
        setUsePolitical(!usePolitical);
    }

    const changeUserData = (key: string, value: string) => {
        changeLeadHookData(lead, setLead, key, value);
    }

    const validateFormPageFunction = () => {
        setValidationFormPage({...validationFormPage, user: false})
        if (lead.user.name !== '' && lead.user.email !== '' && lead.user.phone !== '' && usePolitical) {
            setValidationFormPage({...validationFormPage, user: true})
        }
    }

    useEffect(() => {
        validateFormPageFunction();
    }, [lead, usePolitical, setValidationFormPage])

    return (
        <Grid container justifyContent={'center'}>
            <Grid item xs={12}>

                <Typography variant={'body1'} fontWeight={600} mb={3} mt={3} className={'landingFormTitles'}
                            dangerouslySetInnerHTML={createMarkup('Datos de contacto')}/>

                <Grid container justifyContent={'center'} spacing={3}>

                    <Grid item xs={12}>
                        <CustomTextField fullWidth
                                         size={'small'}
                                         required={true}
                                         value={lead.user.name}
                                         label={'Nombre completo'}
                                         id="userContactFormNameDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('name', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextField fullWidth
                                         size={'small'}
                                         required={true}
                                         value={lead.user.email}
                                         label={'Correo electrónico'}
                                         id="userContactFormEmailDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('email', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12}>
                        <CustomTextField fullWidth
                                         size={'small'}
                                         required={true}
                                         value={lead.user.phone}
                                         label={'Número de teléfono'}
                                         id="userContactFormPhoneDetail"
                                         onChange={(e: ChangeEvent<HTMLInputElement>) => changeUserData('phone', e.target.value)}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} mt={3}>
                        <FormControlLabel
                            control={<Checkbox checked={usePolitical} onClick={openPoliticalPrivateDialog}/>}
                            label="Aceptar políticas de uso y privacidad"/>
                    </Grid>
                    <CustomDialog title={'Política de Privacidad'} content={<LegalTextContent/>} open={openDialog} setOpen={setOpenDialog} customFunction={checkedUsePoliticalAcceptance}/>

                </Grid>
            </Grid>
        </Grid>
    );
}


export default LeadContactLandingForm;
