import {Divider, Grid, Link, Typography} from "@mui/material";
import * as React from "react";
import {createMarkup} from "../../Shared/utils/textUtilsResources";
import {useTranslation} from "react-i18next";
import {ES_CONTACT, ES_LEGAL_TEXT, ES_RESOURCES, ES_SERVICE, ES_TECHNOLOGY, ES_US} from "../../routes/constantsRoutes";

const QuickLinks = () => {

    const {t} = useTranslation('', {keyPrefix: 'web.footer'})

    const quickFooterLinks = [
        {
            title: t('sections.us'),
            path: ES_US
        },
        {
            title: t('sections.contact'),
            path: ES_CONTACT
        },
        {
            title: t('sections.blog'),
            path: ES_RESOURCES
        },
        {
            title: t('sections.faq'),
            path: ES_RESOURCES + '#faqs'
        },
        {
            title: t('sections.service'),
            path: ES_SERVICE
        },
        {
            title: t('sections.technology'),
            path: ES_TECHNOLOGY
        },
        {
            title: t('sections.legalText'),
            path: ES_LEGAL_TEXT
        }
    ];

    return (
        <Grid container justifyContent={'center'} className={'footerQuickLinksContainer'}>
            <Grid item xs={10} mb={2}>
                <Typography variant={'body1'} fontSize={'1em'} color={'white'}
                            className={'footerQuickLinksParagraph'}
                            dangerouslySetInnerHTML={createMarkup(t('quickLinks'))}/>
                <Divider style={{ backgroundColor: 'white', width: '20%'}}/>
            </Grid>


            {quickFooterLinks.map((section, index) => {
                return (
                    <Grid key={index} item xs={10} mt={2}>
                        <Link rel={'canonical'} key={'main_page'} href={`${section.path}`}
                              underline="none">
                            <Typography variant={'body1'} fontSize={'1em'} color={'white'}
                                        fontWeight={300} className={'footerQuickLinksParagraph'}
                                        dangerouslySetInnerHTML={createMarkup(section.title)}/>
                        </Link>

                    </Grid>
                );
            })}
        </Grid>
    );
}

export default QuickLinks;
